// IMPORTS
@import "../base/settings";

.comp.cta-list-section {
    padding-bottom: 60px;

    a.cta-list-btn {
        display: block;
        padding: 40px 20px;
        border-radius: 24px;
        border: 1px solid $lightgrey;
        @include flex($justify:center);
        align-items: center;

        .cta-list-label {
            display: block;
            text-decoration: underline;
            font-size: 1.2em;
            font-weight: 600;
            color: $blue;
            text-align: center;
        }
    }
}
/*importing bootstrap from node-modules*/
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/variables";

// IMPORTS
@import "base/settings";
@import "base/reset";
@import "base/header";
@import "base/navigation";
@import "base/typography";
@import "base/footer";
@import "base/theme";

@import "pages/home";
@import "pages/locations";
@import "pages/news";
@import "pages/search";

@import "components/buttons";
@import "components/rte";
@import "components/forms";
@import "components/hero";
@import "components/rates";
@import "components/icon-cards";
@import "components/display-cards";
@import "components/fifty-fifty";
@import "components/divider";
@import "components/articles";
@import "components/sub-pages-cards";
@import "components/gallery";
@import "components/video";
@import "components/accordions";
@import "components/cta-list";
@import "components/testimonials";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

body.cu1-site {
    font-family: neue-haas-unica, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: $bodytext;

    a, a.btn button, input, select {
        &:focus-visible, &:focus {
            outline: 0;
            box-shadow: inset 0 0 0 0.16rem rgb(255 255 255 / 80%), 0 0 0 0.16rem rgb(18 27 31 / 80%);
        }
    }

    a.cu1-hyperlink {
        text-decoration: underline;

        &:hover, &:focus {
            text-decoration: none;
            box-shadow: none;
        }
    }

    a:not(.cu1-btn) {
        &:focus {
            box-shadow: none;
        }
    }
}

.p-md-4 {
    @media #{$above768} {
        padding: ($spacer * 2.25) !important;
    }
}

.px-md-4 {
    @media #{$above768} {
        padding-left: ($spacer * 2.25) !important;
        padding-right: ($spacer * 2.25) !important;
    }
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

.page {
    background-color: #fff;
}

.tab-skip {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 30;
}

.sr-only.sr-only-focusable {
    position: absolute;
    display: block;
    width: 240px !important;
    height: 27px;
    padding: 4px 30px;
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    text-align: center;
}

p a {
    font-weight: 700;
}

.mediumblue {
    color: #003865;
}
.blue {
    color: #3381e2;
}
.cyan {
    color: #93e6d0;
}
.darkblue {
    color: #051c2c;
}
.white {
    color: #fff;
}

span.blue {
    display: inline-block;
    font-weight: 700;
    color: #3381e2;
}

.component-heading, .upcoming-title-area {
    @include flex($justify:space-between);
    margin-bottom: 30px;

    a.cu1-btn, a.cu1-hyperlink {
        align-self: flex-start;
        margin-top: 12px;
    }

    @media #{$above991} {
        margin-bottom: 0;
    }
}

.flex-col {
    display: flex;
    flex-direction: column;

    .flex-fill {
        -webkit-box-flex: 1;
        flex: 1;
    }
}

ul.multi-col-link-list {
    margin: 30px 0;

    li a {
        display: block;
        color: $bodytext;
        text-decoration: underline;
        padding: 5px 0;

        &:hover, &:focus {
            color: $link-color;
            text-decoration: underline;
        }
    }
}

.comp.alert-section {
    margin: 0;
    padding: 12px 0;

    .cu1-alert {
        position: relative;
        font-size: .9em;
        padding-right: 40px;

        p {
            margin: 0;
        }

        button.btn-close {
            position: absolute;
            top: 15px;
            right: 0;
        }
    }

    &.theme-blue {
        @extend .dark-theme-include;
        background-color: $mediumblue;
    }

    &.theme-lavender {
        background-color: $lavender;
    }

    &.theme-peach {
        background-color: $peach;
    }

    &.theme-cyan {
        background-color: $cyan;
    }

    &.theme-grey {
        background-color: $lightgrey;
    }

    &.theme-dark-blue {
        @extend .dark-theme-include;
        background-color: $darkblue;
    }
}

.comp.disclaimer-section {
    margin: 60px 0;

    .cu1-card {
        padding: 30px 60px;
        background-color: $lightgrey;
        font-size: .9em;

        p {
            font-style: italic;
        }
    }
}




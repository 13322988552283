﻿// IMPORTS
@import "../base/settings";

.comp.upmc-rte {
    padding: 30px 0;

    .rte-img {
        max-width: 100%;
        margin: 0 0 1.6rem;

        img.upmc-figure-image {
            max-width: 236px;
            max-height: 160px;
        }

        &.left, &.right {
            max-width: 100%;
            float: none;
            margin: .5rem auto 1.6rem;
        }

        &.left {
            @media #{$above768} {
                margin: .5rem 3rem 1.6rem 0;
                max-width: 236px;
                max-height: 160px;
                float: left;
            }
        }

        &.right {
            @media #{$above768} {
                margin: .5rem 0 1.6rem 3rem;
                max-width: 236px;
                max-height: 160px;
                float: right;
            }
        }
    }

    &.rte-padded {
        @media #{$above768} {
            padding-left: 14%;
            padding-right: 14%;
        }

        @media #{$above991} {
            padding-left: 20%;
            padding-right: 20%;
        }
    }

    @media #{$above768} {
        padding: 0;
    }
}

.comp.infographic-section {
    padding: 30px 0;
}
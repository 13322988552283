﻿// ----------------------------------------------------------------------
// VARIABLES, MIXINS & EXTENDS
// ----------------------------------------------------------------------
@use 'sass:math';

// Color Variables

$darkblue: #051c2c;
$mediumblue: #003865;
$lightblue: #d6e6f9;
$blue: #3381e2;
$cyan: #93e6d0;
$light-cyan: #d4f5ec;
$peach: #fff1ee;
$lavender: #e4e3fb;
$lightgrey: #ebebeb;

$bodytext: #051c2c;


// Media Query Variables
$above1920: "(min-width: 1920px)";
$above1600: "(min-width: 1600px)";
$above1440: "(min-width: 1440px)";
$above1400: "(min-width: 1400px)";
$above1240: "(min-width: 1240px)";
$above1200: "(min-width: 1200px)";
$above1024: "(min-width: 1024px)";
$above991: "(min-width: 991px)";
$above940: "(min-width: 940px)";
$above768: "(min-width: 768px)";
$above576: "(min-width: 576px)";
$above480: "(min-width: 480px)";
$above375: "(min-width: 375px)";

$tabletRange: "(min-width: 991px) and (max-width: 1200px)";

$below1276: "(max-width: 1276px)"; //where the browser window hits the max page size
$below1200: "(max-width: 1199px)";
$below991: "(max-width: 990px)";
$below940: "(max-width: 939px)";
$below768: "(max-width: 767px)";


// Mixins & Extends
@mixin transition($sec: .1s) { 
    -webkit-transition: all $sec ease-in; 
	-moz-transition: all $sec ease-in; 
	-o-transition: all $sec ease-in;
	transition: all $sec ease-in;
}

@mixin standard-box-shadow($size:1px, $opacity:0.18) {
    -webkit-box-shadow: 1px 1px $size 0 rgba(0,0,0,$opacity);
    -moz-box-shadow: 1px 1px $size 0 rgba(0,0,0,$opacity);
    box-shadow: 1px 1px $size 0 rgba(0,0,0,$opacity);
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin flex($wrap: wrap, $justify: flex-start) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: $wrap;
    justify-content: $justify;
}

@mixin cover-image() {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

@mixin caption($position: absolute) {
    position: $position;
    bottom: 0;
    width: 100%;
    padding: 12px 0;
    /*background-color: #666d70;*/
    color: #666d70;
    font-size: .8em;

    &.left-justified {
        text-align: left;
    }

    p {
        margin: 0;
        padding: 0;
    }
}

@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

$min-vw: 991px;
$max-vw: 2000px;
$min-font-size: 16px;
$max-font-size: 24px;

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin hide-show($breakpoint:$above768) {
    &.desktop {
        display: none;

        @media #{$breakpoint} {
            display: block;
        }
    }

    &.mobile {
        display: block;

        @media #{$breakpoint} {
            display: none;
        }
    }
}

@mixin center-link() {
    display: inline-block;
    text-align: center;
    position: relative;

    span[class*="fa"] {
        position: absolute;
        margin: 0;
        top: 4px;
        right: -14px;
    }

    &:hover, &:focus {
        span[class*="fa"] {
            right: -16px;
        }
    }
}

@mixin standard-link() {
    a:not(.upmc-btn) {
        text-decoration: underline;
        font-weight: 600;
        @include transition;
        color: $pink;

        &:hover {
            color: $purple;
        }
    }
}

@mixin standard-list() {
    ul:not(.lined-list), ol:not(.numbered-list) {
        margin: 15px 0 15px 0;
        padding-left: 30px;

        li {
            padding: 8px;
        }

        ul, ol {
            margin: 0;
        }
    }

    ul:not(.lined-list) {
        > li {
            list-style-type: disc;
        }

        ul li {
            list-style-type: circle;
        }

        ul ul li {
            list-style-type: disc;
        }

        ul ul ul li {
            list-style-type: circle;
        }

        ul ul ul ul li {
            list-style-type: disc;
        }

        ol li {
            list-style-type: decimal;
        }

        ol ol li {
            list-style-type: upper-alpha;
        }

        ol ol ol li {
            list-style-type: decimal;
        }

        ol ol ol ol li {
            list-style-type: lower-alpha;
        }

        ol ol ol ol ol li {
            list-style-type: lower-roman;
        }
    }

    ol:not(.numbered-list) {
        > li {
            list-style-type: decimal;
        }

        ol li {
            list-style-type: upper-alpha;
        }

        ol ol li {
            list-style-type: decimal;
        }

        ol ol ol li {
            list-style-type: lower-alpha;
        }

        ol ol ol ol li {
            list-style-type: lower-roman;
        }

        ul li {
            list-style-type: disc;
        }

        ul ul li {
            list-style-type: circle;
        }

        ul ul ul li {
            list-style-type: disc;
        }

        ul ul ul ul li {
            list-style-type: circle;
        }
    }
}

//Bootstrap spacing default
$spacer: 1rem;





// IMPORTS
@import "../base/settings";

.comp.full-width-video-section {
    margin: 60px 0;
}

.fw-video-container {
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;
    height: 0;
    border-radius: 24px;

    a.video-placeholder {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        padding-bottom: 56.25%;
        z-index: 2;

        img {
            @include cover-image;
        }
    }

    iframe {
        @include cover-image;
        z-index: 1;
    }
}      


// IMPORTS
@import "../base/settings";

.comp.articles-section {
    margin: 60px 0;

    .article-title-area {
        text-align: center;
        margin-bottom: 40px;

        p {
            margin-top: 40px;
        }

        @media #{$above991} {
            padding: 0 120px;
        }
    }

    .article-card {
        overflow: hidden;
        padding: 0;
        max-width: 416px;
        margin-right: auto;
        margin-left: auto;
        height: 100%;

        .article-image-container {
            position: relative;
            border-radius: 24px 24px 0 0;
            overflow: hidden;
            padding-top: calc(250px/414px * 100%);

            &:before {
                display: block;
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 50px;
                background-image: url(/images/dotpattern-blue-tall.png);
                background-repeat: repeat-x;
                background-position-y: 5px;
                z-index: 2;
            }
            
            img {
                @include cover-image();
            }
        }

        .article-text-container {
            padding: 30px 30px 40px 30px;
            border: 1px solid #e9e9e9;
            border-radius: 0 0 24px 24px;
            height: 100%;

            h3.article-title {
                margin-top: 0;
                font-size: 1.6em;
            }

            p {
                margin-bottom: 30px;
            }
        }
    }
}

ul.pagination {
    @include flex();
    list-style: none;

    li {
        a.pagination-link {
            background-color: #fff;
            color: $darkblue;
            border: 1px solid $darkblue;
            border-radius: 50px;
            padding: 10px 20px;
            margin: 0 5px;
            text-decoration: none;
            transition: background-color 0.3s ease;

            &:hover, &:focus {
                color: #fff;
                background-color: $darkblue;
            }

            &.active {
                color: #fff;
                background-color: $darkblue;
            }
        }
    }
}
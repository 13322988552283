// IMPORTS
@import "../base/settings";

.comp.image-gallery-section {
    margin: 60px 0;

    a.gallery-item {
        display: block;
        border-radius: 24px;
        overflow: hidden;
        position: relative;
        padding-top: 67.434%;

        img {
            @include cover-image;
            z-index: 0;
        }

        &:before {
            content: "";
            background-color: #000;
            opacity: 0;
            z-index: 1;
            @include cover-image;
            @include transition;
        }

        span.gallery-caption {
            display: block;
            opacity: 0;
            position: absolute;
            top: 12px;
            left: 12px;
            color: #fff;
            z-index: 2;
            @include transition;
        }

        &:hover, &:focus {
            &:before {
                opacity: .4;
            }

            span.gallery-caption {
                opacity: 1;
            }
        }
    }
}
// IMPORTS
@import "../base/settings";

.news-page {
    padding-top: 40px;

    .comp.hero-internal-section {
        h1.hero-large-text {
            font-size: 3em;
            margin-bottom: 15px;
        }

        > .container-lg {
            border-bottom: 0;
        }

        .hero-large-graphic-content {
            background-image: url(/images/dot-cutout.png);
            background-repeat: repeat-y;
            background-position: top left;
            background-size: 100%;
            padding-top: 1px;

            &.theme-blue {
                background-color: $mediumblue;
            }

            &.theme-peach {
                background-color: $peach;
            }

            &.theme-lavender {
                background-color: $lavender;
            }

            &.theme-cyan {
                background-color: $cyan;
            }
        }

        .featured-article-image {
            border-radius: 24px;
            overflow: hidden;
            position: relative;
            width: 553px;
            height: 406px;
            margin-top: 74px;

            img {
                @include cover-image;
            }         
        }
    }

    .article-body-section {
        padding-top: 60px;
        padding-bottom: 60px;
        border-top: 1px solid $lightgrey;
    }

    .article-sidebar {
        padding-top: 20px;

        @media #{$above991} {
            padding-top: 80px;
        }
    }
    
}

.read-time {
    font-size: .9em;
}
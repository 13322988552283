// IMPORTS
@import "../base/settings";

.comp.section-divider {
    margin: 60px 0;

    .divider-content {
        text-align: center;
        background-image: url(/images/divider-lines.png);
        background-repeat: repeat-x;
        background-position: center;

        .logo-content {
            width: 159px;
            height: 159px;
            margin: auto;
            padding: 20px;
            background-color: #fff;

            &.without-logo {
                background-color: transparent;
            }
        }
    }
}
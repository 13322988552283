// IMPORTS
@import "../base/settings";

.search-results-top {
    padding: 60px 0;
}

.search-result {
    padding: 30px 0;
    border-top: 1px solid $lightblue;

    a.result-title {
        font-weight: 700;
    }
}

.search-results {
    padding-bottom: 60px;
}

.search-crumbs {
    display: inline-block;
    margin-top: 10px;
    padding: 4px 24px 6px 24px;
    background-color: $lightgrey;

    ul.breadcrumbs {

        li {
            display: inline-block;
            font-size: .8em;

            a {
                margin-right: 6px;
            }

            &.home a {

            }
        }
    }
}

.loc-search-wrap {
    @include flex($wrap:nowrap);
    position: relative;
    align-items: stretch;
    width: 100%;

    input.loc-search-input {
        border-radius: 50px;
        height: 45px;
        padding: 8px 20px;
        width: 100%;
        border: 1px solid $lightblue;
    }

    button.submit-mag {
        position: absolute;
        right: 0;
        border-radius: 50px;
        border: 0;
        width: 45px;
        height: 45px;
        background-color: $blue;
        color: #fff;
        @include flex($justify:center);
        align-items: center;
    }
}
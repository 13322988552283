// IMPORTS
@import "settings";

nav.cu1-eyebrow-nav, nav.cu1-account-navigation {
    .cu1-btn {
        margin-left: 15px;

        &:first-child {
            margin-left: 0;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: $darkblue;
        }

        @media #{$below1200} {
            margin-left: 0;
            transform: scale(0.9);
        }
    }
}

nav.cu1-eyebrow-nav {
    display: none;

    .contact-dropdown-segment {
        margin: 15px 0;
        padding-right: 30px;

        .contact-label {
            display: block;
            font-weight: 700;
        }
    }

    @media #{$above991} {
        @include flex($wrap:nowrap, $justify:flex-end);
    }
}

.cu1-desktop-navbar {
    background-color: $darkblue;
    padding: 10px 0;
    display: none;
    position: static;
    z-index: 10;
    @include transition;

    &.sticky {
        position: sticky;
        top: 0;
        width: 100%;

        @media #{$below1200} {
            .container-lg {
                max-width: 100%;
            }
        }

        nav.cu1-primary-navigation {
            @include flex();
            align-items: center;

            a.sticky-nav-logo {
                opacity: 1;
                width: 68px;

                img {
                    display: block;
                }
            }

            @media #{$below1200} {
                flex-wrap: nowrap;
            }
        }
    }

    @media #{$above991} {
        display: block;
    }
}

nav.cu1-primary-navigation {
    a.sticky-nav-logo {
        opacity: 0;
        width: 0;
        display: inline-block;
    }
    .cu1-btn {
        margin-right: 15px;

        &:first-child {
            margin-right: 0;
        }

        @media #{$below1200} {
            margin-right: 0;
            transform: scale(0.9);
        }
    }

    .dropdown-toggle:after {
        display: none;
    }

    @media #{$below1200} {
        margin-left: -4px;
    }
}

.dropdown-menu.cu1-dropdown {
    margin-top: 10px!important;
    border-radius: 24px;
    border-color: transparent;
    padding: 16px 30px;
    max-width: 490px;
    @include standard-box-shadow($size:10px);

    &.show {
        @include flex(); 
    }

    h3.dropdown-heading {
        width: 100%;
        border-bottom: 1px solid $lightgrey;
        padding-bottom: 20px;
    }

    li {
        width: 50%;

        a {
            color: $blue;
            padding: 10px 0;

            &.dropdown-item {
                &:active {
                    background-color: transparent;
                }
            }
        }
    }
        
    &:before {
        content: "";
        position: absolute;
        top: -8px;
        left: 30px;
        transform: translateX(-50%);
        border-width: 0 10px 10px 10px;
        border-style: solid;
        border-color: transparent transparent #ffffff transparent;
    }
}

nav.cu1-account-navigation {
    display: none;

    @media #{$above991} {
        @include flex($wrap:nowrap, $justify:flex-end);
    }

    @media #{$below1200} {
        margin-right: -4px;
        font-size: .9em; 
    }
}

.cu1-mobile-navbar {
    background-color: $darkblue;
    padding: 10px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;

    .mobile-navbar-content {
        @include flex($wrap:nowrap, $justify:space-between);
        align-items: center;
    }

    .mobile-nav-divider {
        height: 50px;
        width: 2px;
        background-color: #fff;
        opacity: .5;
    }

    a.mobile-login-link {
        color: #fff;
        padding: 20px 14px;
        width: 100%;
        text-align: center;
        white-space: nowrap;

        &.login-mobile {
            color: $cyan;
        }
    }

    @media #{$above991} {
        display: none;
    }
}

#cludo-search-form {
    padding: 10px 30px;
    background-color: $mediumblue;
    position: relative;
    @include flex();
    align-items: center;

    input.mobile-search-input {
        border-radius: 30px;
        background-color: $darkblue;
        color: #fff;
        width: 100%;
        border: 0px;
        padding: 12px 12px 12px 70px;
    }

    input.mobile-search-input::placeholder {
        color: #fff;
    }

    button.mobile-search-mag {
        position: absolute;
        border: 0;
        outline: none;
        color: $blue;
        background-color: transparent;
        left: 50px;
    }
}

.mm-wrapper__blocker {
    /*background-color: transparent;*/
}

.mm-page {
    min-height: calc(var(--vh, 1vh) * 100);
}

.mm-menu {
    /*top: 78px;*/
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    /*height: calc(var(--vh, 1vh) * 100 - 78px);*/
    background: #003865;
    border-color: #051c2c;
    --mm-listitem-size: 70px;
    --mm-color-text: #051c2c;
    --mm-color-icon: #3381e2;

    .mm-navbars--top {
        border-bottom: 1px solid $darkblue;

        .mm-navbar {
            height: 80px;
            text-transform: uppercase;
        }

        .custom-close-navbar {
            justify-content: flex-end;
            background-color: $darkblue;
            color: #fff;

            .mm-navbar__title {
                display: block;
                flex: none;
                padding: 30px 20px 0 0;
                text-transform: none;
                font-weight: 400;
            }
        }

        .custom-search-navbar {
            background-color: $mediumblue;
            color: #fff;
        }
    }

    #mm-1 {
        .mm-navbar {
            display: none;
        }
    }

    .mm-navbars--top {
        
    }

    .contact-dropdown-segment {
        padding: 30px;
        border-bottom: 1px solid $darkblue;
    }

    .mm-navbar {
        background-color: $darkblue;
        color: #fff;
        font-size: 1.2em;
        font-weight: 400;
        height: 30px;

        a.mm-navbar__btn {
            --mm-color-icon: #3381e2;
        }
    }

    .mm-listitem__text {
        padding-left: 30px;
        padding-right: 30px;
    }

    .mm-listitem {
        font-size: 1.2em;
        font-weight: 400;
        background-color: $mediumblue;
        color: #fff;

        &:after {
            left: 0;
        }

        .mm-listitem__btn {
            border-left: 0;
        }

        .mm-btn--next:after, .mm-btn--prev:before {
            width: 16px;
            height: 16px;
            border-width: 2px;
            border-color: #3381e2;
        }

        &.highlight-link {
            background-color: #fff;
            color: $darkblue;
        }
    }

    .mm-searchfield {
        height: 80px;

        input {
            height: 50%;
            background-color: #fff;
            border-radius: 0;
            margin-bottom: 0;
            border: 1px solid $lightblue;
        }
    }

    .search_autocomplete {
        display: none;
    }

    .box-search {
        width: 100%;

        label[for="txtNavSearch"] {
            font-size: .9em;
        }
    }

    ul.main-mobile-menu:after {
        display: none;
    }
}

a.mm-wrapper__blocker {
    /* Disable focus styling */
    outline: none;
    /* Prevent pointer events */
    pointer-events: none;
    /* Prevent focusing */
    user-select: none;
}

// IMPORTS
@import "../base/settings";

.comp.faq-section {
    margin: 60px 0;

    .faq-info-title {
        padding-bottom: 15px;
    }

    .faq-answer {
        padding-right: 10px;
    }
}

.accordion.cu1-accordion {
    .cu1-accordion-item {
        border-bottom: 1px solid $lightgrey;

        h2.accordion-header {
            button.accordion-button {
                font-size: 1em;
                font-weight: 700;

                &:not(.collapsed) {
                    background-color: $lavender;
                }
            }
        }

        .accordion-body {
            padding: 40px 20px;
        }
    }
}
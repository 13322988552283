// IMPORTS
@import "../base/settings";

.comp.sub-pages-cards-section {
    margin: 60px 0;

    h2.main-heading {
        margin-bottom: 0;
    }

    &.theme-blue {
        .dots-card {
            &:before {
                background-color: $lightblue;
            }

            &:after {
                background-image: url(/images/dotpattern-lightblue.png);
            }
        }
    }

    &.theme-cyan {
        .dots-card {
            &:before {
                background-color: $light-cyan;
            }

            &:after {
                background-image: url(/images/dotpattern-cyan.png);
            }
        }
    }
}

.dots-card {
    border: 2px solid $lightgrey;
    border-radius: 24px;
    overflow: hidden;
    padding: 40px 40px 100px 40px;
    /*margin-top: 40px;*/
    /*max-width: 416px;*/
    margin-right: auto;
    margin-left: auto;
    position: relative;
    width: 100%;

    .dots-card-text {
        margin-bottom: 20px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 40px;
        background-color: $lightblue;
        z-index: 2;
    }

    &:after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        bottom: 40px;
        width: 100%;
        height: 28px;
        background-image: url(/images/dotpattern-lightblue.png);
        background-repeat: repeat-x;
        z-index: 2;
    }

    h3 {
        font-size: 1.8em;
        margin-bottom: 12px;
    }

    a.cu1-hyperlink {
        color: $darkblue;
        display: inline-block;
        font-weight: 600;
        margin-top: 14px;
        padding-left: 26px;
    }
}
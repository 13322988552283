// IMPORTS
@import "../base/settings";

.comp.icon-cards-section {
    margin: 60px 0;

    .cu1-card.icon-card {
        width: 100%;
        display: block;
        padding: 40px;
        text-decoration: underline;
        color: $bodytext;
        text-align: center;
        /*background-color: $peach;*/
        margin-bottom: 20px;
        @include transition($sec:.2s);

        @media #{$above991} {
            margin-bottom: 0;
        }
    }

    a.cu1-card.icon-card {
        &:hover, &:focus {
            transform: scale(1.1);
            text-decoration: none;
        }
    }

    &.icons-with-text {
        h2.main-heading {
            margin-bottom: 40px;
        }

        .cu1-card.icon-card {
            text-align: left;
            text-decoration: none;

            .icon-card-image {
                @include flex($justify:flex-start);
            }
        }
    }
}

.icon-card-image {
    height: auto;
    @include flex($justify:center);
    align-content: center;
    margin-bottom: 20px;

    img {
        max-height: 100%;
        display: block;
    }

    @media #{$above480} {
        height: 86px;
    }

    @media #{$above991} {
        height: 123px;
    }
}
﻿// IMPORTS
@import "../base/settings";

a.cu1-btn, button.cu1-btn, input.cu1-btn, input[type=submit] {
    background-color: $blue;
    color: #fff;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.4;
    white-space: nowrap;
    padding: 6px 26px 8px;
    position: relative;
    display: inline-block;
    border-radius: 50px;
    border: 1px solid $blue;
    text-decoration: none;
    @include transition();

    &.btn-blue {
        background-color: $blue;
        color: #fff;
        border: 1px solid $blue;

    }

    &.btn-white {
        background-color: #fff;
        color: $darkblue;
        border-color: #d6e6f9;
    }

    &.btn-dark {
        background-color: $darkblue;
        border-color: #003865;
        color: #fff;

        &.current {
            color: $darkblue;
            background-color: $lightblue;
        }
    }

    &.btn-cyan {
        background-color: $cyan;
        color: $darkblue;
        border-color: $cyan;
    }

    &.cu1-search-btn {
        padding: 6px 12px 8px;
    }

    &:hover, &:focus {
        color: $darkblue;
        background-color: $lightblue;
    }
}

.btn-primary {
    border-color: transparent;

    &:hover, &:focus {
        border-color: transparent;
    }
}


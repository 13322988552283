﻿// IMPORTS
@import "settings";

.cu1-footer-links-section {
    background-color: $mediumblue;
    background-image: url(/images/dotpattern-blue-tall.png);
    background-repeat: repeat-x;
    background-position: bottom left;
    padding-bottom: 124px;
    padding-top: 50px;

    .footer-links-col {
        text-align: center;

        @media #{$above768} {
            text-align: left;
        }
    }

    h2.footer-links-heading {
        color: $cyan;
    }

    ul.footer-links {
        margin-bottom: 30px;

        li {
            a {
                display: block;
                color: #fff;
                text-decoration: underline;
                padding: 5px 0;
            }
        }
    }
}

footer.cu1-footer {
    background-color: $darkblue;
    padding: 50px 0;
    border-top: 16px solid $cyan;

    .footer-logos-row {
        text-align: center;

        @media #{$above991} {
            align-items: center;
            @include flex($wrap:nowrap, $justify:space-between);
        }

        .logo-list {
            width: 100%;
            align-items: center;
            @include flex($wrap:wrap, $justify:space-between);

            @media #{$above991} {
                flex-wrap: nowrap;
                padding-left: 100px;
            }

            .logo-item {
                width: 50%;
                padding: 30px 20px;
                text-align: center;

                @media #{$tabletRange} {
                    transform: scale(0.9);
                }

                @media #{$above991} {
                    width: auto;
                    padding: 0;
                }
            }

            .logo-nav-divider {
                display: none;
                height: 100px;
                width: 2px;
                background-color: #003865;

                @media #{$above991} {
                    display: block;
                }
            }
        }
    }

    .footer-social-copyright-row {
        padding: 50px 0;
        margin: 50px 0;
        border-top: 2px solid #003865;
        border-bottom: 2px solid #003865;

        @media #{$above991} {
            align-items: center;
            @include flex($wrap:nowrap, $justify:space-between);
        }

        .footer-social-links {
            @include flex($wrap:wrap, $justify:space-around);
            padding: 0 40px;

            a.footer-social-icon {
                @include transition;
                display: block;
                color: $blue;
                font-size: 1.7em;

                &:last-child {
                    margin-right: 0;
                }

                &:hover, &:focus {
                    color: #fff;
                }

                @media #{$above991} {
                    margin-right: 24px;
                }
            }

            @media #{$above991} {
                padding: 0;
                flex-wrap: nowrap;
                justify-content: flex-start;
            }
        }

        .footer-copyright {
            color: #fff;
            font-size: .9em;
            text-align: center;

            @media #{$above991} {
                text-align: right;
                align-items: center;
                @include flex($wrap:nowrap, $justify:flex-end);
            }

            .copyright-item {
                padding: 20px 0;

                @media #{$above991} {
                    padding: 0;
                    margin-left: 30px;
                }
            }
        }
    }

    .footer-legal-row {
        padding: 50px 0;

        &.non-numbered-disclosures {
            padding-bottom: 0;
        }

        .legal-copy {
            color: #fff;
        }
    }
}

.legal-copy {
    font-size: .7em;

    span {
        display: inline-block;
        float: left;
        margin-right: 10px;
    }

    > sup {
        float: left;
        display: inline-block;
        padding-right: 5px;
    }
}

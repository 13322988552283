﻿// IMPORTS
@import "settings";

h1, h2, h3, h4, h5, h6 {
    font-family: neue-haas-unica, sans-serif;
    color: $darkblue;
    line-height: 1.2;
}

h2.main-heading, h2.section-heading {
    font-weight: 600;
    font-size: 2.4em;
}

p {
    margin-bottom: 20px;
    font-size: 1em;
    line-height: 1.5;
}

h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 2.1em;
    margin-bottom: 6px;
}

h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4em;
    margin-top: 12px;
    margin-bottom: 16px;
}

h3 {
    font-weight: 400;
    font-size: 1.3em;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 16px;
}

h4 {
    font-weight: 600;
    font-size: 1.2em;
    margin-top: 12px;
    margin-bottom: 16px;
}

h5 {
    font-weight: 600;
    font-size: 1.1em;
    margin-bottom: 12px;
}

h6 {
    font-size: 1em;
    font-weight: 600;
    margin-top: 6px;
    margin-bottom: 10px;
}

b {
    font-weight: 600;
}

i, em {
    font-style: italic;
}

strong, b {
    font-weight: 600;
}

sup {
    font-size: .8em;
    display: inline-block;
}

.cu1-rte, .display-card-text-content, .fifty-text-content, .hero-large-text, .icon-card-content {
    a:not(.cu1-btn) {
        text-decoration: underline;
        font-weight: 500;
        @include transition;
        color: $blue;

        &:hover {
            color: $darkblue;
        }
    }

    .rte-img {
        display: block;
        margin-bottom: 30px;
        float: left;
        margin-right: 30px;
        max-width: 320px;

        &.left {
            float: right;
            margin-right: 0;
            margin-left: 30px;
        }
    }

    ul:not(.checklist), ol:not(.numbered-list) {
        margin: 15px 0 15px 0;
        padding-left: 30px;

        li {
            padding: 8px;
        }

        ul, ol {
            margin: 0;
        }
    }

    ul:not(.lined-list) {
        > li {
            list-style-type: disc;
        }

        ul li {
            list-style-type: circle;
        }

        ul ul li {
            list-style-type: disc;
        }

        ul ul ul li {
            list-style-type: circle;
        }

        ul ul ul ul li {
            list-style-type: disc;
        }

        ol li {
            list-style-type: decimal;
        }

        ol ol li {
            list-style-type: upper-alpha;
        }

        ol ol ol li {
            list-style-type: decimal;
        }

        ol ol ol ol li {
            list-style-type: lower-alpha;
        }

        ol ol ol ol ol li {
            list-style-type: lower-roman;
        }
    }

    ol:not(.numbered-list) {
        > li {
            list-style-type: decimal;
        }

        ol li {
            list-style-type: upper-alpha;
        }

        ol ol li {
            list-style-type: decimal;
        }

        ol ol ol li {
            list-style-type: lower-alpha;
        }

        ol ol ol ol li {
            list-style-type: lower-roman;
        }

        ul li {
            list-style-type: disc;
        }

        ul ul li {
            list-style-type: circle;
        }

        ul ul ul li {
            list-style-type: disc;
        }

        ul ul ul ul li {
            list-style-type: circle;
        }
    }

    ul.lined-list {
        list-style-type: none;
        margin: 16px 0;
        text-align: left;
        padding-left: 0;

        li {
            padding: 4px 0 0 34px;
            list-style-type: none;
            position: relative;
        }

        &.two-column {
            @media #{$above768} {
                column-count: 2;
            }
        }
    }

    dl {
        margin: 15px 0;

        dt {
            float: left;
            clear: left;
            font-weight: 700;
            padding-right: 30px;

            &:after {
                content: ":";
            }
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin: 30px 0;

        th {
            background-color: $darkblue;
            border-right: 1px solid $lightgrey;
            color: white;
            text-align: left;
            padding: 10px 8px;
            font-weight: 700;

            &:last-of-type {
                border-right: 0;
            }
        }

        tr {

            td:last-of-type {
                border-right: 0;
            }
        }

        tr:nth-of-type(odd) {

        }

        tr:last-of-type {
            td {
                border-bottom: 0;
            }
        }

        td {
            border-bottom: 1px solid $darkblue;
            border-right: 1px solid $darkblue;
            padding: 10px 8px;
            text-align: left;
        }

        p {
            margin: 0;
        }
    }
}

figure {
    position: relative;
    display: table;
    max-width: 100%;
    margin: 0;

    a {
        display: block;
    }

    figcaption img {
        display: block;
        width: 100%;
    }

    figcaption {
        @include caption($position:relative);
        display: table-caption;
        caption-side: bottom;

        a {
            display: inline;
        }
    }

    &.infographic-figure {
        margin: auto;
        text-align: center;

        .figure-caption-wrapper {
            text-align: left;
        }

        img.infographic-image {
            width: 100%;
        }
    }
}

.infographic-title-area {
    text-align: center;
}

@-moz-document url-prefix() {
    figure figcaption {
        display: block !important;
    }
}

/*Have to target FireFox to fix issue*/
@-moz-document url-prefix() {
    .infographic-image {
        width: auto;
    }
}

blockquote {
    position: relative;
    overflow: hidden;
    padding: 30px;
    margin: 60px 0;
    background-color: $lavender;
    border-radius: 24px;

    p {
        font-size: 1.8em;
        font-weight: 600;
        line-height: 1.4;
        margin: 0;
        padding-top: 20px;

        &:before {
            content: "";
            display: block;
            height: 40px;
            margin-bottom: 20px;
            width: 100%;
            background-image: url(/images/quote-left.png);
            background-position: left top;
            background-repeat: no-repeat;
        }
    }
}


// IMPORTS
@import "../base/settings";

.locations-page-wrapper {
    padding: 40px 0;
    position: relative;

    .locations-search-container {
        @media #{$above991} {
            padding-right: 30px;
        }
    }

    ul.search-filters {
        list-style-type: none;
        padding: 20px 0 0 0;
        @include flex();

        li {
            font-size: .8em;

            &:last-child label {
                margin-right: 0;
            }

            label {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                margin-right: 20px;

                input[type="checkbox"] {
                    margin-right: 6px;
                    appearance: none;
                    width: 16px;
                    height: 16px;
                    border-radius: 0;
                    border: 1px solid $bodytext;
                    outline: none;

                    &:checked {
                        background-color: $bodytext; /* Change the background color when checked */
                        border-color: $bodytext; /* Change the border color when checked */
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                .label-text {
                    flex: 1;
                }
            }
        }
    }

    a.shared-branching-link {
        display: block;
        margin-bottom: 30px;
    }
}

#view-filters {
    @include flex($justify:center);
    margin-bottom: 20px;
    position: relative;

    button {
        background-color: #fff;
        color: $bodytext;
        border: 1px solid $lightgrey;
        margin-bottom: 0;
        padding: 9px 17px;
        width: 50%;
    }

    @media #{$above991} {
        display: none;
    }
}

ul.action-menu {
    @include flex();
    list-style-type: none;
    padding-left: 0;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 0;
    top: 100px;
    z-index: 110;

    li {
        @include flex();
        max-width: 48px;
        transition: max-width .75s ease;
        overflow: hidden;
        white-space: nowrap;
        height: 48px;
        background-color: $darkblue;
        position: relative;

        button {
            padding: 0 0 0 10px;
            border: 0;
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            width: 40px;
        }

        a {
            color: #fff;
            display: inline-block;
            background-color: $darkblue;
            padding: 0.75rem 1rem 0.75rem 10px;
            margin-left: 38px;
        }

        button {
            color: #fff;
            background-color: $darkblue;
            display: inline-block;
            height: 48px;
        }

        &:hover, &:focus {
            width: auto;
            max-width: 300px;
        }
    }
}

.filter-results-container {
    .filter-results-list {
        padding-right: 20px;
        
        @media #{$above991} {
            overflow-y: scroll;
            height: calc(100vh - 340px);
        }

        .location-box {
            border: 2px solid $lightgrey;
            padding: 20px;
            margin-bottom: 30px;
            position: relative;

            &.virtual-location {
                border: 2px solid $lightblue;
            }

            span.box-number {
                display: block;
                position: absolute;
                left: -10px;
                top: 20px;
                width: 66px;
                height: 26px;
                background-color: $blue;
                color: #fff;
                padding-left: 20px;
                line-height: 1.4;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -5px;
                    left: 1px;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 7px solid #051c2c;
                    transform: rotate(45deg);
                    z-index: -1;
                }
            }

            h2.loc-title {
                padding-left: 50px;
                margin: 0 0 30px 0;
            }

            h2, p {
                a {
                    color: $bodytext
                }
            }

            ul, p {
                font-size: .8em;
            }

            .cu1-btn {
                margin-bottom: 15px;
            }

            ul.service-list {
                margin-bottom: 30px;

                li {
                    display: inline-block;
                    margin-right: 10px;
                }
            }
        }
    }

    .view-filters-mobile {
        @media #{$above991} {
            display: none;
        }
    }
}

.location-map-container {
    position: relative;
    height: 500px;
    display: block;

    @media #{$above991} {
        height: 100%;
    }

    iframe {
        @include cover-image();
        position: relative;
    }
}

.location-detail-map {
    position: relative;
    height: 380px;
    display: block;

    iframe {
        @include cover-image();
        position: relative;
    }
}

/* HTML marker styles */
.map-pin {
    background-color: #4285F4;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #4285F4;
    }
}

.location-details-section {
    padding-bottom: 40px;
}

.location-details-column {
    @media #{$above991} {
        font-size: .9em;
        padding-left: 30px;
    }

    .detail-row {
        @include flex($wrap:nowrap);
        width: 100%;
        border-bottom: 1px solid $lightgrey;
        padding: 14px 0;

        .ldc-label {
            display: inline-block;
            width: 200px;
            font-weight: 700;
            flex-shrink: 0;
        }

        .ldc-content {

        }

        .ldc-item {
            display: block;
            margin-bottom: 5px;
        }
    }
}

.hours-detail-row {
    .ldc-content {
        @include flex($wrap:wrap, $justify:flex-start);
        padding: 30px 0;

        @media #{$above991} {
            flex-wrap: nowrap;
            justify-content: center;
        }

        .hours-item {
            padding: 20px;
            text-align: center;
            border-left: 1px solid $lightgrey;
            width: 50%;

            @media #{$above991} {
                width: auto;
                padding: 0 20px;
            }

            @media #{$above576} {
                width: 25%;
            }

            strong {
                display: block;
                text-align: center;
            }

            .hours-comment {
                display: block;
                font-size: .8em;
                padding-top: 5px;
                color: #5d5d5d;
            }

            &:first-child {
                border-left: 0;
            }

            .hours-labels-content {
                font-size: .9em;
            }
        }
    }
}

﻿// IMPORTS
@import "../base/settings";

.umbraco-forms-form {
    input[type=text], input[type=email], input[type=file], textarea {
        background-color:white;
        border-radius:0;
        width: 100%!important;
        max-width: unset!important;
        border: 0;
        padding: 8px 20px;
        font-size: 1em;
        color: $darkblue;
        border: 1px solid $darkblue;
    }

    input[type=checkbox] {
        width: 12px;
        height: 12px;
    }

    .form-control {
        appearance: auto;
    }
 
    textarea {
        min-height: 240px;
        border: 1px solid $darkblue;
    }

    legend {
        border-top: 1px solid grey;
        border-bottom: 1px solid grey;
        padding: 12px;
    }

    h4 {
        margin-bottom: 15px;
    }

    .umbraco-forms-field {
        margin-top: 12px;
    }

    .umbraco-forms-field-wrapper {
        margin-bottom: 30px;
    }

    .umbraco-forms-fieldset {
        margin-bottom: 0;
    }
    
    label {
        color: $darkblue;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .checkboxlist {
        @include flex();

        input[type=checkbox] {
            margin: 4px 4px 0 0;
        }

        label {
            margin: 0 12px 0 0;
        }
    }
}

// IMPORTS
@import "../base/settings";

.comp.fifty-image-with-text-section {
    margin: 60px 0;

    &.image-right {
        .row {
            flex-direction: row-reverse;
        }

        .fifty-text-content {
            @media #{$above991} {
                padding-right: 30px;
            }
            
        }
    }

    &.image-left {
        .fifty-text-content {
            @media #{$above991} {
                padding-left: 30px;
            }
        }
    }
}
// IMPORTS
@import "../base/settings";

.comp.display-card-section {
    margin: 60px 0;

    .cu1-card.display-card {
        padding: 20px;

        @media #{$above991} {
            padding: 50px 100px;
        }
    }

    &.image-left {
        .display-card-image-content {
            @media #{$above991} {
                text-align: left;
            }

            @media #{$tabletRange} {
                padding-right: 30px;
            }
        }
    }

    &.image-right {
        .row {
            flex-direction: row-reverse;
        }

        .display-card-image-content {
            padding-top: 40px;

            @media #{$above991} {
                text-align: right;
                padding-top: 0;
            }
        }
    }

    .display-card-image-content {
        text-align: center;
    }

    .display-card-text-content {
        @include vertical-align;

        h2.main-heading {
            margin-top: 0;
        }

        .display-card-buttons-content {
            padding-top: 20px;
            @include flex($wrap:nowrap, $justify:flex-start);
            align-items: center;

            a {
                margin-right: 10px;

                &.cu1-hyperlink {
                    color: $darkblue;
                    font-weight: 600;
                    margin-left: 30px;
                }
            }
        }
    }

    &.three-column-card {
        h3 {
            font-size: 1.6em;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 30px;
        }

        .icon-card-content {
            text-align: center;
            margin-bottom: 30px;

            @media #{$above991} {
                text-align: left;
                margin-bottom: 0;
            }
        }

        .icon-card-image {
            justify-content: center;

            @media #{$above991} {
                justify-content: flex-start;
            }
        }

        &.upcoming-events-section {
            .up-event-item {
                margin: 30px 0;

                @media #{$above1200} {
                    min-height: 200px;
                }
            }

            h4.up-event-title {
                border-bottom: 1px solid rgba(255,255,255,.4);
                padding-bottom: 15px;
            }

            a.cu1-btn {
                margin-top: 20px;
            }

            a.cu1-hyperlink {
                display: block;
                text-decoration: underline;
            }

            span.event-date {
                display: block;
                opacity: .4;
            }
        }
    }

    
}

ul.checklist {
    list-style: none!important;
    margin: 0;
    padding: 0;

    li {
        list-style-type: none!important;
        position: relative;
        padding-left: 34px;
        margin: 22px 0;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 26px;
            height: 26px;
            background-image: url(/images/check-icon.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}


﻿// IMPORTS
@import "settings";

header.cu1-header {
    background-image: url(/images/dotpattern-lightblue.png);
    background-position: top left;
    background-repeat: repeat-x;
    padding-top: 28px;
    background-color: #fff;
}

a.logo-link {
    display: block;

    @media #{$below768} {
        max-width: 70%;
        margin: auto;
    }

    img {
        max-width: 100%;
    }
}

.header-content {
    padding: 34px 0;
    text-align: center;

    @media #{$above991} {
        padding: 44px 0;
        text-align: left;
    }
}

.mobile-contact-nav {
    @include flex($wrap:nowrap, $justify:center);
    background-color: $lightblue;

    .mobile-contact-nav-item {
        width: 100%;
        text-align: center;
        border-right: 1px solid #fff;
        padding: 10px 0;

        &:last-child {
            border-right: 0;
        }

        span.mcn-title {
            display: block;
        }
    }

    @media #{$above991} {
        display: none;
    }
}

a.hamburger {
    @include vertical-align(absolute);
    display: block;
    right: 20px;
    margin: 0;
    padding: 0;
    z-index: 10;

    .hamburger-inner {
        background-color: $darkblue;
        border-radius: 0;

        &:before, &:after {
            background-color: $blue;
            border-radius: 0;
        }
    }

    @media #{$above991} {
        display: none;
    }
}

// IMPORTS
@import "../base/settings";

.comp.hero-large-section {
    padding: 40px 0 80px;

    .hero-large-graphic-content {
        margin-bottom: -50px;
    }
}

.comp.hero-internal-section {
    padding: 40px 0;
}

.comp.hero-large-section, .comp.hero-internal-section {
    background-repeat: repeat-x;
    background-position: bottom left;

    .hero-large-text-content {
        @media #{$above991} {
            padding-top: 40px;
        }
    }

    h1.hero-large-text {
        font-weight: 600;
        font-size: 2.4em;
        margin-bottom: 30px;
        line-height: 1.4;

        @media #{$above991} {
            font-size: 3em;
        }

        @media #{$above1200} {
            font-size: 3.8em;
        }
    }

    &.theme-blue {
        background-color: $blue;
        background-image: url(/images/dotpattern-darkblue-tall.png);

        .title-two {
            color: #fff;
        }
    }

    &.theme-cyan {
        background-color: $light-cyan;
        background-image: url(/images/dotpattern-cyan-tall.png);

        .title-two {
            color: $blue;
        }
    }

    &.theme-lavender {
        background-color: $lavender;
        background-image: url(/images/dotpattern-lavender-tall.png);

        .title-two {
            color: $blue;
        }
    }

    &.theme-peach {
        background-color: $peach;
        background-image: url(/images/dotpattern-peach-tall.png);

        .title-two {
            color: $blue;
        }
    }

    .hero-large-graphic-content {
        @media #{$below991} {
            margin-top: 40px;
        }
    }

    .hero-large-buttons-content {
        margin-top: 40px;
        @include flex();
        align-items: center;

        .cu1-btn {
            margin-right: 10px;
            margin-bottom: 10px;

            @media #{$above991} {
                margin-bottom: 0;
            }
        }

        .title-one {
            color: $darkblue;
        }

        .title-two {
            color: #fff;
        }
    }

    &.content-right {
        .row {
            flex-direction: row-reverse;
        }

        .hero-large-text-content, .hero-large-buttons-content {
            @media #{$above991} {
                padding-left: 20px;
            }
        }
    }
}

.comp.hero-internal-section {
    > .container-lg {
        /*border-bottom: 1px solid $lightgrey;*/
        padding-bottom: 20px;
    }

    .hero-large-text-content {
        padding-top: 0;
    }
    
    .hero-large-text-content, .hero-large-buttons-content {
        @media #{$above991} {
            padding-right: 40px;
        }
    }

    .hero-large-graphic-content {
        @media #{$above991} {
            padding-left: 40px;
        }
    }

    .breadcrumb {
        display: none;
    }

    &.with-breadcrumb {
        /*.hero-large-text {
            @media #{$above991} {
                margin-top: 100px;
            } 
        }*/

        .breadcrumb {
            display: block;
        }
    }
}
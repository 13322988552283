// IMPORTS
@import "../base/settings";

.comp.testimonial-section {
    padding-bottom: 60px;

    .cu1-card.testimonial-card {
        padding: 20px;

        @media #{$above991} {
            padding: 50px 100px;
        }

        .testimonial-quote {
            font-size: 3.2em;

            span {
                mix-blend-mode: multiply;
            }
        }

        p.testimonial-text {
            font-size: 2.2em;
            font-weight: 600;
            line-height: 1.4;
        }
    }
}
// IMPORTS
@import "../base/settings";

.comp.rates-row-section {
    margin: 60px 0;

    img.logo-small {
        
    }

    .rates-item {
        text-align: center;
        border-bottom: 2px solid #e9e9e9;
        padding: 40px 0;

        @media #{$above991} {
            padding: 20px 0 40px 0;
            border-left: 2px solid #e9e9e9;
            border-bottom: 0;
        }

        &.intro-item {
            text-align: center;
            border-left: 0;
            @include vertical-align();

            @media #{$above991} {
                text-align: left;
            }
        }

        h2 {
            font-size: 2em;
        }

        span {
            
        }

        .cu1-btn {
            margin-top: 30px;
        }

        .rates-item-title {
            display: block;
        }

        .rates-apy {
            display: block;
        }

        .rates-number {
            font-weight: 600;
            font-size: 3.8em;
            display: block;

            .percent {
                font-size: 0.6em;
            }
        }

        a.disclosures-link {
            display: block;
            text-align: center;
            font-size: .7em;
            padding: 14px 0 0 0;
        }
    }
}
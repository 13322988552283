// IMPORTS
@import "settings";

.cu1-card {
    border-radius: 24px;
}

.theme-lavender {
    .cu1-card {
        background-color: $lavender;
    }

    .dots-card {
        &:before {
            background-color: $lavender;
        }

        &:after {
            background-image: url(/images/dotpattern-lavender.png);
        }
    }

    .testimonial-quote {
        color: $lavender;
    }
}

.theme-lavender-dots {
    .cu1-card {
        @extend .dot-theme-include;

        &:before {
            background-color: $lavender;
        }

        &:after {
            background-image: url(/images/dotpattern-lavender.png);
        }
    } 

    .dots-card {
        &:before {
            background-color: $lavender;
        }

        &:after {
            background-image: url(/images/dotpattern-lavender.png);
        }
    }
}

.theme-blue {
    .cu1-card {
        @extend .dark-theme-include;
        background-color: $mediumblue;

        &.display-card {
            background-image: url(/images/dotpattern-cyan-tall.png);
            background-repeat: repeat-x;
            background-position: bottom left;
            padding-bottom: 124px!important;

            h2.main-heading {
                color: $cyan;
            }
        }

        .testimonial-quote {
            color: $mediumblue;
        }
    }

    .dots-card {
        &:before {
            background-color: $lightblue;
        }

        &:after {
            background-image: url(/images/dotpattern-lightblue.png);
        }
    }
}

.theme-blue-dots {
    .cu1-card {
        @extend .dot-theme-include;

        &:before {
            background-color: $lightblue;
        }

        &:after {
            background-image: url(/images/dotpattern-lightblue.png);
        }
    } 

    .dots-card {
        &:before {
            background-color: $lightblue;
        }

        &:after {
            background-image: url(/images/dotpattern-lightblue.png);
        }
    }
}

.theme-dark-blue {
    @extend .dark-theme-include;

    .cu1-card {
        background-color: $darkblue;

        h2.main-heading {
            color: $cyan;
        }
    }
}

.theme-peach {
    .cu1-card {
        background-color: $peach;
    }

    .dots-card {
        &:before {
            background-color: $peach;
        }

        &:after {
            background-image: url(/images/dotpattern-peach.png);
        }
    }

    .testimonial-quote {
        color: $peach;
    }
}

.theme-peach-dots {
    .cu1-card {
        @extend .dot-theme-include;

        &:before {
            background-color: $peach;
        }

        &:after {
            background-image: url(/images/dotpattern-peach.png);
        }
    }
    
    .dots-card {
        &:before {
            background-color: $peach;
        }

        &:after {
            background-image: url(/images/dotpattern-peach.png);
        }
    }
}

.theme-cyan {
    .cu1-card {
        background-color: $light-cyan;
    }

    .dots-card {
        &:before {
            background-color: $light-cyan;
        }

        &:after {
            background-image: url(/images/dotpattern-cyan.png);
        }
    }

    .testimonial-quote {
        color: $cyan;
    }
}

.theme-cyan-dots {
    .cu1-card {
        @extend .dot-theme-include;

        &:before {
            background-color: $light-cyan;
        }

        &:after {
            background-image: url(/images/dotpattern-cyan.png);
        }
    } 

    .dots-card {
        &:before {
            background-color: $light-cyan;
        }

        &:after {
            background-image: url(/images/dotpattern-cyan.png);
        }
    }
}

.theme-grey {
    .cu1-card {
        background-color: $lightgrey;
    }
}

.theme-outline {
    .cu1-card {
        background-color: #fff;
        border: 1px solid $lightgrey;
    }
}

.comp.hero-large-section, .comp.hero-internal-section, .fifty-image-with-text-section {
    &.theme-blue {
        background-color: $mediumblue;
    }

    &.theme-dark-blue {
        background-color: $darkblue;
    }

    &.theme-lavender {
        background-color: $lavender;
    }

    &.theme-cyan {
        background-color: $cyan;
    }

    &.theme-peach {
        background-color: $peach;
    }
}

.dark-theme-include {
    color: #fff;

    h2:not(.section-heading), h3, h4, ul, ol, p {
        color: #fff;
    }

    a.cu1-hyperlink {
        color: #fff;
    }
}

.dot-theme-include {
    position: relative;
    border: 2px solid $lightgrey;
    overflow: hidden;
    background-color: #fff;
    padding-bottom: 100px!important;

    &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 40px;
        z-index: 2;
    }

    &:after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        bottom: 40px;
        width: 100%;
        height: 28px;
        background-repeat: repeat-x;
        z-index: 2;
    }

    a.cu1-btn.btn-dark {
        background-color: $blue;
        color: #fff;
        border: 1px solid $blue;

        &:hover, &:focus {
            color: $darkblue;
            background-color: $lightblue;
        }
    }
}




